
























import { Table, TableColumn } from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { Concept } from "@/models";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';

interface OpeningHoursDataObject {
  day: string;
  open: string;
  close: string;
}

const ConceptLogoProps = Vue.extend({
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  }
})

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Translations,
  },
  computed: {},
  mixins: [translations],
})
export default class ConceptImageLogo extends ConceptLogoProps {
  translate!: Function;
  logoUri: string = "";
  openingHours: Array<OpeningHoursDataObject> = [];

  filesUploadChange(event: any) {
    this.data['logo-uri']= URL.createObjectURL(event.target.files[0]);
    this.data['logo-image']= event.target.files[0];
  }

  created() {
    this.logoUri = this.data['logo-uri']
  }
}
